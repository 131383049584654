import { ReactNode } from 'react';
import dynamic from 'next/dynamic';
import { usePage } from '~/services/cms';

const layouts = {
  standard: dynamic(() => import('./layouts/standard')),
  p100CheckoutPage: dynamic(() => import('./layouts/checkout')),
};

function getLayout(pageType: string | undefined) {
  switch (pageType) {
    case 'p100CheckoutPage':
      return layouts.p100CheckoutPage;
    case 'P060StandardPage':
    default:
      return layouts.standard;
  }
}

export function DynamicLayout({ children }: { children: ReactNode }) {
  const { data: page } = usePage();
  const Layout = getLayout(page?.type);

  return <Layout>{children} </Layout>;
}
