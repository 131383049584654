import dynamic from 'next/dynamic';
import { ComponentType } from 'react';
import { usePage } from '~/services/cms';

const pageTypes: { [key: string]: ComponentType<{}>; } = {
  'module': dynamic(() => import('./page-types/module')),
  'p180login': dynamic(() => import('./page-types/P180LoginPage')),
  'p181profile': dynamic(() => import('./page-types/P181Profile')),
  'p182resetPassword': dynamic(() => import('./page-types/P182NewPasswordPage')),
  'p200favorites': dynamic(() => import('./page-types/P200Favorites'))
};

export function DynamicPage() {
  const { data: page } = usePage();
  const PageType = (page?.type && pageTypes[page.type]) || pageTypes.module;

  return <PageType />;
}
