import { dehydrate, QueryClient } from '@tanstack/react-query';
import type { GetStaticPaths, GetStaticProps } from 'next';
import { ReactElement } from 'react';
import { minutes } from 'utils';
import locale from '~/constants/locale.mjs';
import { DynamicLayout } from '~/layout';
import { DynamicPage } from '~/page-type';
import { queryResolver } from '~/services/query-resolver';
import { DynamicPageProps } from '~/types';

const fallbackLocale = locale.defaultLocale || 'da';

export const getStaticProps: GetStaticProps<DynamicPageProps, PageParams> = async (context) => {
    const { params, locale, defaultLocale = fallbackLocale } = context;
    const currentLocale = locale || defaultLocale;

    // Fetch frame, page, translations
    const queryClientPage = new QueryClient();
    const { /*page,*/ pageNotFound } = await queryResolver({
        locale: currentLocale,
        slug: params?.slug,
        queryClientPage
    });

    //   if (page?.type === 'redirect') {
    //     const { destination, permanent } = page;
    //     return {
    //       revalidate: permanent ? months(1, true) : minutes(5, true),
    //       redirect: { destination, permanent },
    //     };
    //   }

    if (pageNotFound) {
        return { revalidate: minutes(5, true), notFound: true };
    }

    return {
        revalidate: minutes(1, true),
        props: {
            dehydratedState: dehydrate(queryClientPage),
        },
    };
};

export const getStaticPaths: GetStaticPaths = async () => {
    return {
        paths: [], // Fetch list of content pages from CMS or just generate pages the fly
        fallback: 'blocking',
    };
};

export default function SlugPage() {
    return <DynamicPage />;
}

SlugPage.getLayout = function getLayout(page: ReactElement) {
    return <DynamicLayout>{page}</DynamicLayout>;
};

type PageParams = {
    slug: string[];
};
